import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PostCard from './../components/PostCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import Preloader from '../components/Preloader';

const Posts = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('todos');
  const [loading, setLoading] = useState(true);
  const [searchTag, setSearchTag] = useState('');
  const [searchTags, setSearchTags] = useState([]);

  const fetchPosts = async () => {
    try {
      const response = await axios.get('https://espawfinder.com/backend/upload/all');
      if (response.data.files) {
        setAllPosts(response.data.files);
        setDisplayedPosts(response.data.files.slice(0, 10));
        setHasMore(response.data.files.length > 10);
      } else {
        console.error('No files found in response:', response.data);
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    filterPosts();
  }, [filter, allPosts, searchTags]);

  const filterPosts = () => {
    const newFilteredPosts = allPosts.filter(post => {
      if (filter !== 'todos' && (post.type && post.type.toLowerCase() !== filter)) {
        return false;
      }
      for (let tag of searchTags) {
        if (
          !post.breed.toLowerCase().includes(tag) &&
          !post.color.toLowerCase().includes(tag) &&
          !post.location.toLowerCase().includes(tag) &&
          !post.healthStatus.toLowerCase().includes(tag)
        ) {
          return false;
        }
      }
      return true;
    });
    setDisplayedPosts(newFilteredPosts.slice(0, 10));
    setHasMore(newFilteredPosts.length > 10);
    setPage(1);
  };

  const fetchMoreData = () => {
    const newPage = page + 1;
    const newFilteredPosts = allPosts.filter(post => {
      if (filter !== 'todos' && (post.type && post.type.toLowerCase() !== filter)) {
        return false;
      }
      for (let tag of searchTags) {
        if (
          !post.breed.toLowerCase().includes(tag) &&
          !post.color.toLowerCase().includes(tag) &&
          !post.location.toLowerCase().includes(tag) &&
          !post.healthStatus.toLowerCase().includes(tag)
        ) {
          return false;
        }
      }
      return true;
    });
    const morePosts = newFilteredPosts.slice((newPage - 1) * 10, newPage * 10);
    setDisplayedPosts(prevPosts => [...prevPosts, ...morePosts]);
    setPage(newPage);
    setHasMore(newFilteredPosts.length > newPage * 10);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleSearchTagChange = (e) => {
    setSearchTag(e.target.value);
  };

  const handleAddTag = () => {
    if (searchTag && !searchTags.includes(searchTag)) {
      setSearchTags([...searchTags, searchTag.toLowerCase()]);
      setSearchTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const newTags = searchTags.filter(tag => tag !== tagToRemove);
    setSearchTags(newTags);
  };

  return (
    <div className="container mt-5"  style={{ overflowX: 'hidden' }}>
      <div className="main-content col-lg-9">
        <h2>All Posts</h2>
        <ul className="nav nav-pills category-isotope center-nav mt-5">
          <li className="nav-item">
            <button className={`nav-link ${filter === 'todos' ? 'active' : ''}`} onClick={() => handleFilterChange('todos')}>Todos</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${filter === 'perro' ? 'active' : ''}`} onClick={() => handleFilterChange('perro')}>Solo Perros</button>
          </li>
          <li className="nav-item">
            <button className={`nav-link ${filter === 'gato' ? 'active' : ''}`} onClick={() => handleFilterChange('gato')}>Solo Gatos</button>
          </li>
        </ul>
        {loading ? (
          <Preloader />
        ) : (
          <InfiniteScroll
            dataLength={displayedPosts.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<p>Loading...</p>}
            endMessage={<p>No more posts</p>}
          >
            <div id="gallery-isotope" className="row row-eq-height mt-lg-5">
              {displayedPosts.length === 0 ? (
                <p>No posts available</p>
              ) : (
                displayedPosts.map((post) => (
                  <PostCard key={post._id.$oid} post={post} />
                ))
              )}
            </div>
          </InfiniteScroll>
        )}
      </div>
      <div id="sidebar" className="sidebar bg-light h-100 col-lg-3 card pattern3">
        <div className="widget-area">
          <h5 className="sidebar-header">Search</h5>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search for..."
              value={searchTag}
              onChange={handleSearchTagChange}
            />
            <span className="input-group-btn">
              <button className="btn btn-secondary btn-sm" type="button" onClick={handleAddTag}>Go!</button>
            </span>
          </div>
        </div>
        <div className="widget-area">
          <h5 className="sidebar-header">Tags</h5>
          <div className="tags-widget">
            {searchTags.map(tag => (
              <span key={tag} className="badge badge-pill badge-default">
                {tag} <button type="button" className="btn btn-sm btn-danger" onClick={() => handleRemoveTag(tag)}>x</button>
              </span>
            ))}
          </div>
        </div>
        <div className="widget-area">
          <h5 className="sidebar-header">Follow us</h5>
          <div className="contact-icon-info">
            <ul className="social-media text-center">
              <li><a href="#"><i className="fab fa-facebook-square"></i></a></li>
              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#"><i className="fab fa-pinterest"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Posts;
