import React from 'react';

const Preloader = () => {
  return (
    <div id="preloader publicidad">
      <div className="preloader-logo">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
