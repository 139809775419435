import React from 'react';
import { useNavigate } from 'react-router-dom';
import MediaCarousel from './MediaCarousel';
import axios from 'axios';

const PostCard = ({ post }) => {
  const navigate = useNavigate(); // Obtener navigate de useNavigate
  const { 
    _id, title, breed, type, color, gender, age, size, petCondition, lostOrFoundDate,
    lostOrFoundLocation, latitude, longitude, ownerName, reward, ownerPhone,
    ownerEmail, ownerMessage, imagePaths = [], videoPaths = [] 
  } = post;

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://espawfinder.com/backend/upload/${_id}`);
      console.log(response.data); // Muestra la respuesta en la consola
      navigate(`/post/${_id}`); // Usar navigate para redirigir
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  };

  return (
    <div className={`col-lg-6 ${type ? type.toLowerCase() : ''}`} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className="isotope-item">
        <div className="adopt-card res-margin row bg-light pattern2">
          <div className="col-md-5">
            <div className="adopt-image d-flex flex-wrap align-items-center">
              <MediaCarousel
                media={[
                  ...imagePaths.map((path) => ({ type: 'image', path })),
                  ...videoPaths.map((path) => ({ type: 'video', path })),
                ]}
              />
            </div>
          </div>
          <div className="col-md-7 res-margin">
            <div className="caption-adoption">
              <h5 className="adoption-header">{title}</h5>
              <ul className="list-unstyled">
                {breed && <li><strong>Breed: </strong>{breed}</li>}
                {type && <li><strong>Type: </strong>{type}</li>}
                {color && <li><strong>Color: </strong>{color}</li>}
                {gender && <li><strong>Gender: </strong>{gender}</li>}
                {age && <li><strong>Age: </strong>{age}</li>}
                {size && <li><strong>Size: </strong>{size}</li>}
                {petCondition && <li><strong>Pet Condition: </strong>{petCondition}</li>}
                {lostOrFoundDate && <li><strong>Lost or Found Date: </strong>{new Date(lostOrFoundDate).toLocaleDateString()}</li>}
                {lostOrFoundLocation && <li><strong>Location: </strong>{lostOrFoundLocation}</li>}
                {latitude && <li><strong>Latitude: </strong>{latitude}</li>}
                {longitude && <li><strong>Longitude: </strong>{longitude}</li>}
                {ownerName && <li><strong>Owner: </strong>{ownerName}</li>}
                {reward && <li><strong>Reward: </strong>{reward}</li>}
                {ownerPhone && <li><strong>Phone: </strong>{ownerPhone}</li>}
                {ownerEmail && <li><strong>Email: </strong>{ownerEmail}</li>}
                {ownerMessage && <li><strong>Message: </strong>{ownerMessage}</li>}
              </ul>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="text-center">
              <ul className="adopt-card-info list-unstyled">
                <li><i className="flaticon-veterinarian-hospital"></i> Special needs</li>
                <li><i className="flaticon-dog-4"></i> Friendly to other pets</li>
              </ul>
              <button className="btn btn-primary" onClick={handleClick}>More Information</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCard;
