// frontend/src/screens/SinglePost.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const SinglePost = () => {
  const { id } = useParams(); // Obtén el ID del post desde la URL
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://espawfinder.com/backend/upload/${id}`);
        setPost(response.data);
      } catch (error) {
        console.error('Error fetching post:', error);
      }
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <p>Cargando publicación...</p>;
  }

  if (!post) {
    return <p>No se encontró la publicación.</p>;
  }

  const { title, breed, type, color, gender, age, size, petCondition, imagePaths } = post;

  return (
    <div className="container mt-5">
      <h1>{title}</h1>
      <div className="row">
        <div className="col-md-6">
          <img
            src={`https://espawfinder.com/backend/uploads/${imagePaths[0]}`}
            alt={title}
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            {breed && <li><strong>Raza:</strong> {breed}</li>}
            {type && <li><strong>Tipo:</strong> {type}</li>}
            {color && <li><strong>Color:</strong> {color}</li>}
            {gender && <li><strong>Género:</strong> {gender}</li>}
            {age && <li><strong>Edad:</strong> {age}</li>}
            {size && <li><strong>Tamaño:</strong> {size}</li>}
            {petCondition && <li><strong>Condición:</strong> {petCondition}</li>}
          </ul>
          <button onClick={() => window.history.back()} className="btn btn-secondary mt-3">
            Volver
          </button>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;
